export class Marcadores{
    constructor(
        // public uid: string,
        public longitud: string,
        public latitud: string,
        public nombre: string,
        public apellidoP: string,
        public telefono: string,
        public zona?: string,
        public seccion?: string,
        // public micro: string,
        // public colonia: string,
        // public calle: string,
        // public createAt: string,
        // public numeroCasa: string,
        // public necesidades: string,
        // public necesidad2: string,
        // public necesidad3: string
    ){}
}
