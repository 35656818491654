export class DiezD{
    constructor(
        public uid: string,
        public apellidoM: string,
        public apellidoP: string,
        public apoyo: string,
        public callCenter: string,
        public calle: string,
        public claveElector: string,
        public colonia: string,
        public createAt: string,
        public email: string,
        public facebook: string,
        public fechaN: string,
        public image: string,
        public image2: string,
        public latitud: string,
        public longitud: string,
        public micro: string,
        public movilizacion: string,
        public necesidad2: string,
        public necesidad3: string,
        public necesidades: string,
        public nombre: string,
        public numeroCasa: string,
        public pVoto: string,
        public prop: string,
        public redAmigos: string,
        public seccion: string,
        public telefono: string,
        public uidUser: string,
        public zona: string,
        public carta: string,
        public bingo: string,
        public uidUserCC?: string,
        public fechaCarta?: string,
        public latitudCarta?: string,
        public longitudCarta?: string,
        public fechaModificacion?: string,
        public fechaBingo?: string,
        public fechaLlamada?: string,
        public fechaCallCenter?: string,
        public notas?: string,
        public votantes?: string,
        public nuevaPreferencia?: string,
    ){}
}