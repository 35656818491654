import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment'
import { DiezD } from '../models/verificado';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Marcadores } from '../models/markets';

import { Zonas } from '../models/zonas';
import { Secciones } from '../models/secciones';
import { Micros } from '../models/micros';
import { Bingo } from '../models/bingo';

moment.locale('en');

@Injectable({
  providedIn: 'root'
})

export class VerificadoService {

  observable:Observable<DiezD[]>;  

  constructor(
    private http: HttpClient
  ) { }
  
  quitarAcentos(cadena){
    // const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
    const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','ñ':'n','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U','Ñ':'N'};
    return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
  }
  
  getDiezD(): Observable<DiezD[]> {
    return this.http.get<any>(`${environment.url_api}diezD`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.Diez)).map(item => {                
          return new DiezD(
            item.id,
            this.quitarAcentos(item.data.apellidoM.trim().toUpperCase()),
            this.quitarAcentos(item.data.apellidoP.trim().toUpperCase()),
            item.data.apoyo,
            item.data.callCenter,
            // this.quitarAcentos(item.data.calle.trim().toUpperCase()),
            item.data.calle,
            item.data.claveElector,
            item.data.colonia,
            item.data.createAt,
            item.data.email,
            item.data.facebook,
            item.data.fechaN,
            item.data.image,
            item.data.image2,
            item.data.latitud,
            item.data.longitud,
            item.data.micro,
            item.data.movilizacion,
            item.data.necesidad2,
            item.data.necesidad3,
            item.data.necesidades,
            this.quitarAcentos(item.data.nombre.trim().toUpperCase()),
            item.data.numeroCasa,
            item.data.pVoto,
            item.data.prop,
            item.data.redAmigos,
            item.data.seccion,
            item.data.telefono,
            item.data.uidUser,
            item.data.zona,
            item.data.carta,
            item.data.bingo,
            item.data.uidUserCC,
            item.data.fechaCarta,
            item.data.latitudCarta,
            item.data.longitudCarta,
            item.data.fechaModificacion,
            item.data.fechaBingo,
            item.data.fechaLlamada,
            item.data.fechaCallCenter,
            item.data.notas,
            item.data.votantes,
            item.data.nuevaPreferencia
          ); 
        });
      })
    );
  }

  getRegistrosU(uidUser: string): Observable<DiezD[]> {
    return this.http.get<any>(`${environment.url_api}getRegistros/${uidUser}/registros`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.Registros)).map(item => {                          
          return new DiezD(
            item.id,
            this.quitarAcentos(item.data.apellidoM.trim().toUpperCase()),
            this.quitarAcentos(item.data.apellidoP.trim().toUpperCase()),
            item.data.apoyo,
            item.data.callCenter,
            // this.quitarAcentos(item.data.calle.trim().toUpperCase()),
            item.data.calle,
            item.data.claveElector,
            item.data.colonia,
            item.data.createAt,
            item.data.email,
            item.data.facebook,
            item.data.fechaN,
            item.data.image,
            item.data.image2,
            item.data.latitud,
            item.data.longitud,
            item.data.micro,
            item.data.movilizacion,
            item.data.necesidad2,
            item.data.necesidad3,
            item.data.necesidades,
            this.quitarAcentos(item.data.nombre.trim().toUpperCase()),
            item.data.numeroCasa,
            item.data.pVoto,
            item.data.prop,
            item.data.redAmigos,
            item.data.seccion,
            item.data.telefono,
            item.data.uidUser,
            item.data.zona,
            item.data.carta,
            item.data.bingo,
            item.data.uidUserCC,
            item.data.fechaCarta,
            item.data.latitudCarta,
            item.data.longitudCarta,
            item.data.fechaModificacion,
            item.data.fechaBingo,
            item.data.fechaLlamada,
            item.data.fechaCallCenter,
            item.data.notas,
            item.data.votantes,
            item.data.nuevaPreferencia
          ); 
        });
      })
    );
  }

  getTotalDiezD() {
    return this.http.get<any>(`${environment.url_api}totaldiezD`).pipe(
      map(result => {
        return result.Total;
      })
    );
  }

  getTotalUserDiezD(uidUser: string) {
    return this.http.get<any>(`${environment.url_api}totalUserdiezD/${uidUser}`).pipe(
      map(result => {
        console.log("listo");
      })
    );
  }

  getMarkets(): Observable<Marcadores[]> {
    return this.http.get<any>(`${environment.url_api}markets`).pipe(map(result => {
      return JSON.parse(JSON.stringify(result.Marks)).map(item => {                
        return new Marcadores(
          // item.uid,
          item.lon,
          item.lat,
          item.nom,
          item.ape,
          item.tel,
          // item.zon,
          // item.sec,
          // item.mic,
          // item.col,
          // item.cal,
          // item.cre,
          // item.num,
          // item.nece,
          // item.nec2,
          // item.nec3,
        ); 
      });
    }));
  }

  getMarketsUsers(uidUser: string): Observable<Marcadores[]> {
    return this.http.get<any>(`${environment.url_api}marketsU/${uidUser}`).pipe(map(result => { 
      return JSON.parse(JSON.stringify(result.Marks)).map(item => {                
        return new Marcadores(
          // item.uid,
          item.lon,
          item.lat,
          item.nom,
          item.ape,
          item.tel,
          // item.zon,
          // item.sec,
          // item.mic,
          // item.col,
          // item.cal,
          // item.cre,
          // item.num,
          // item.nece,
          // item.nec2,
          // item.nec3
        ); 
      });
    }));
  }

  getChartZonas(): Observable<Zonas[]> {
    return this.http.get<any>(`${environment.url_api}chartsUZonas`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.ChartZ)).map(item1 => {                
          return new Zonas(
            item1.x,
            item1.y,
          ); 
        });
      })
    );
  }

  // SECCIONES
  getChartSecciones(): Observable<Secciones[]> {
    return this.http.get<any>(`${environment.url_api}chartsUZonas`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.ChartS)).map(item1 => {                
          return new Secciones(
            item1.x,
            item1.y,
          ); 
        });
      })
    );
  }
 
  // MICROS
  getChartMicros(): Observable<Micros[]> { 
    return this.http.get<any>(`${environment.url_api}chartsUZonas`).pipe(
      map(result => {
        return JSON.parse(JSON.stringify(result.ChartM)).map(item1 => {                
          return new Micros(
            item1.x,
            item1.y,
            item1.z
          ); 
        });
      })
    );
  }

  updateDiezD(rest: DiezD) {

    let data = {
      id          : rest.uid,
      apellidoM   : rest.apellidoM,
      apellidoP   : rest.apellidoP,
      apoyo       : rest.apoyo,
      callCenter  : rest.callCenter,
      calle       : rest.calle,
      claveElector: rest.claveElector,
      colonia     : rest.colonia,
      createAt    : rest.createAt,
      email       : rest.email,
      facebook    : rest.facebook,
      fechaN      : rest.fechaN,
      image       : rest.image,
      image2      : rest.image2,
      latitud     : rest.latitud,
      longitud    : rest.longitud,
      micro       : rest.micro,
      movilizacion: rest.movilizacion,
      necesidad2  : rest.necesidad2,
      necesidad3  : rest.necesidad3,
      necesidades : rest.necesidades,
      nombre      : rest.nombre,
      numeroCasa  : rest.numeroCasa,
      pVoto       : rest.pVoto,
      prop        : rest.prop,
      redAmigos   : rest.redAmigos,
      seccion     : rest.seccion,
      telefono    : rest.telefono,
      uidUser     : rest.uidUser,
      zona        : rest.zona,
      carta        : rest.carta,
    }

    console.log(data);
    
    return this.http.put<any>(`${environment.url_api}updateDiezD/`, data).pipe(
      map(result => {
        return result;
      })
    );
  }

  updatestatus(datos) {

    let data = {
      id: datos.uid,
      callCenter: datos.callCenter,
    }

    console.log(data);

    return this.http.put<any>(`${environment.url_api}setCallCenter`, data).pipe(
      map(result => {  
        
                              
        if(result.OK) {
          return result;
        } 
      })
    ); 
  }
  
  deleteUser(uid: string): Observable<DiezD[]> {
    console.log(uid);
    return this.http.post<any>(`${environment.url_api}diezDdelete/${uid}/borrar`, '').pipe(
      map(result => {                
        return result;
      })
    );
  }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }
  
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }


  getBingoS(): Observable<Bingo[]> {     
    return this.http.get<any>(`${environment.url_api}getBingo`).pipe(
      map( result => {       
        return JSON.parse(JSON.stringify(result.Bingos)).map(item => {                     
          return new Bingo(
            item.uid,
            item.fechaBingo,                        
            item.bingo
          );
        })
      })
    );
  }

 

}